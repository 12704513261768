"use client";

import { ReadonlyURLSearchParams, useSearchParams } from "next/navigation";
import {
  FC,
  useMemo,
  useRef,
  useState,
} from "react";
import { Cookies } from "react-cookie";
import { usePostHog } from "posthog-js/react";

import { useAuth } from "@auth/client-sdk-react";
import { useUpdateEffect } from "@/hooks";

import {
  FetchedSources,
  SOURCE_HANDLERS,
  Source,
  TRIGGER_HANDLERS,
  TrackerContext,
  Trigger,
  TriggerName,
  computeRequiredDefaultSources,
} from "../trackers";
import { useAPI } from "../hooks/useAPI";
import {
  useTrackSuccessfulPurchase,
} from "../hooks/useTrackSuccessfulPurchase";

interface Props {
  triggers: Trigger[];
  optionalSources: Source[];
}

interface ITrackerQuery extends ReadonlyURLSearchParams {
  disableTriggers?: string;
}

export type TrackersFired = Partial<Record<Trigger["component"] | Source["component"], boolean | undefined>>;

export const Triggers: FC<Props> = ({
  triggers,
  optionalSources,
}) => {

  const [ fetchedSources, setFetchedSources ] = useState<FetchedSources>({});

  const alreadyFired = useRef<TrackersFired>({});
  const posthog = usePostHog();

  const api = useAPI();

  const auth = useAuth();

  const query: ITrackerQuery = useSearchParams();

  const totalSources = useMemo(() => computeRequiredDefaultSources(
    triggers,
    optionalSources,
  ), [ triggers, optionalSources ]);

  // TODO: This is a hack to make sure the user gets logged in, we shouldn't do this going forward.
  useTrackSuccessfulPurchase({ doNotShowMentionMePopup: !triggers.some(
    (t) => t.component === TriggerName.POST_CHECKOUT_MENTION_ME && t.enablePopup,
  ) });

  // Effect for running the data fetching from the sources
  useUpdateEffect(() => {

    const cookies = new Cookies();
    const context: TrackerContext = {
      posthog,
      cookies,
      query,
      sources: fetchedSources,
      auth,
    };

    async function fetchSources() {
      await Promise.all(totalSources.map(async (s) => {
        if (!s) return;

        if (alreadyFired.current[s.component]) return;

        const handler = SOURCE_HANDLERS[s.component];

        console.debug(`Attempting to fire source handler ${s.component}`);
        try {
          await handler(
            api,
            context,
            s,
            setFetchedSources,
          );
          alreadyFired.current[s.component] = true;
          console.debug(`Source handler ${s.component} has been fired`);
        } catch (err) {
          console.debug(`Source handler ${s.component} failed to fire: ${err}`);
        }
      }));

    }

    void fetchSources();

  }, [ setFetchedSources, totalSources, api, fetchedSources, query, auth, posthog ]);

  // Effect for running the triggers
  useUpdateEffect(() => {

    const cookies = new Cookies();

    const context: TrackerContext = {
      posthog,
      cookies,
      query,
      sources: fetchedSources,
      auth,
    };

    void Promise.all(triggers.map(async (t) => {
      if (query?.get("disableTriggers")?.includes(t.component)) {
        console.log(`${t.component} is disabled.`);
        return;
      }
      const handler = TRIGGER_HANDLERS[t.component];

      if (alreadyFired.current[t.component]) return;

      try {

        console.debug(`Attempting to fire trigger handler ${t.component}`);
        const hasRun = await handler(
          api,
          context,
          // @ts-ignore TypeScript can't infer that the type is correct here
          t,
        );

        if (hasRun) {
          alreadyFired.current[t.component] = true;
          console.debug(`Trigger handler ${t.component} has been fired`);
        } else {
          console.debug(`Trigger handler ${t.component} did not run`);
        }

      } catch (err) {
        console.debug(`Trigger handler ${t.component} failed to fire: ${err}`);
      }
    }));

  }, [ query, triggers, api, auth, fetchedSources, posthog ]);

  return (<></>);

};
