import { ChangeEvent, useCallback, useState } from "react";

export function useNumericInput(initialValue?: number, precision?: number) {

  const [ value, setValue ] = useState(initialValue);

  const parseToNum = useCallback((s: string) => {

    const parsed = Number.parseFloat(s);

    if (Number.isNaN(parsed)) return;
    if (typeof precision === "number") {
      setValue(Number.parseFloat(parsed.toFixed(precision)));
    } else {
      setValue(parsed);
    }
  }, [ setValue ]);

  const onChange = useCallback((eventOrValue : number | string | ChangeEvent<HTMLInputElement>) => {

    if (typeof eventOrValue === "number") {
      setValue(eventOrValue);
      return;
    }

    if (eventOrValue === "") {
      setValue(undefined);
      return;
    }

    const eventString = (typeof eventOrValue === "string")
      ? eventOrValue
      : eventOrValue.target.value;

    parseToNum(eventString);

  }, [ setValue ]);

  return [ value, onChange ] as const;
}
