import nookies from "nookies";

import { PublicConfig } from "apps/website/config/public";

import { PostCheckoutGTMTrigger, TriggerHandler } from "../types";

export const RC_PURCHASED = "rc_purchased";

export const gtmHandler: TriggerHandler<PostCheckoutGTMTrigger> = (
  _api,
  context,
  _trigger,
) => {

  if (!context.cookies.get(RC_PURCHASED)) return true;
  const { posthog } = context;

  const data = context.sources?.firstOrder;

  if (!data) return false;

  if (data.gtmData && data.gtmDataGA4) {

    // @ts-ignore
    if (!nookies.get(data.gtmData.ecommerce.transaction_id)) {
      // @ts-ignore
      nookies.set(null, data.gtmData.ecommerce.transaction_id, "true", {
        path: "/",
      });

      if (typeof window !== undefined && window?.dataLayer) {
        try {
          window.dataLayer.push(data.gtmData);
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push(data.gtmDataGA4);
        } catch (e) {
          console.error("gtmHandler failed");
          console.error(e);
        }
      }

      posthog.capture(
        "purchase",
        // @ts-ignore
        data.gtmDataGA4?.ecommerce,
      );

      context.cookies.remove(RC_PURCHASED, {
        path: "/",
        domain: PublicConfig.NEXT_PUBLIC_COOKIE_DOMAIN,
      });
      return true;
    }
    return false;
  }

  return false;

};
