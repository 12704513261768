import {
  loadMentionMeReferreroffer,
} from "apps/website/utils/mentionme/loadMentionMe";

import { PostCheckoutMentionMeTrigger, TriggerHandler } from "../types";

export const mentionMeHandler: TriggerHandler<PostCheckoutMentionMeTrigger> = (
  _,
  context,
  trigger,
) => {

  const data = context.sources?.firstOrder;

  if (!data) return false;

  // @ts-ignore
  const katkinCustomerId = context.query.get("katkinCustomerId");

  loadMentionMeReferreroffer({
    firstname: data.order.shipping_address.first_name,
    surname: data.order.shipping_address.last_name || "unknown",
    email: data.order.email,
    order_number: data.order.id,
    order_subtotal: data.price_no_vat,
    order_currency: "GBP",
    order_date: data.order.created_at,
    customer_id: katkinCustomerId as string,
    coupon_code: data.order.discount_codes?.at(0)?.code,
    phone_number: data.order.shipping_address.phone,
    fullname: data.order.shipping_address.name,
    address_line1: data.order.shipping_address.address1,
    address_line2: data.order.shipping_address.address2,
    address_city: data.order.shipping_address.city,
    address_county: data.order.shipping_address.province,
    address_postcode: data.order.shipping_address.zip,
    address_country: "United Kingdom",
    custom_share_field: data.catNames,
    custom_field: data.catNames,
    // We want to not show the mentionMe popup but still emit the MentionMe event
    // so that the use of a referral code is tracked. We change implementation to
    // link, since there is (usually) no mmWrapper tag in welcome pages meaning no
    // link will be inserted.
    implementation: trigger.enablePopup ? "popup" : "link",
  });

  return true;

};
