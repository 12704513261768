import { useCallback, useRef, useState } from "react";
import debounce from "lodash.debounce";

import { useUpdateEffect } from "./useUpdateEffect";

type SearchFunction<T, F extends object>
  = (
    _: string,
    pageNumber: number,
    options?: Partial<F>,
    abortController?: AbortController
  ) => Promise<T[]>;

export function useSearch<T, F extends object>(
  searchFn: SearchFunction<T, F>,
  initialPage = 1,
  initialQuery = "",
  initialResults: T[] = [],
  filters?: Partial<F>,
  allowEmptySearch = false,
) {

  const abortController = useRef(new AbortController());

  const [ query, setQuery ] = useState(initialQuery);
  const [ page, setPage ] = useState(initialPage);

  // Reference to previous query to check what's changed
  const prevQuery = useRef(initialQuery);
  const prevFilters = useRef<Partial<F>>(filters ?? {});

  const [ loading, setLoading ] = useState(false);

  const [ results, setResults ] = useState<T[]>(initialResults);

  const onSubmit = useCallback(async (newQuery: string, pageNumber: number, fs?: Partial<F>) => {
    abortController.current.abort();
    setLoading(true);
    const res = await searchFn(
      newQuery,
      pageNumber,
      fs,
      abortController.current,
    );
    setResults(res);
    setLoading(false);
  }, [ searchFn ]);

  const lookup = useCallback(debounce(onSubmit, 500), [ onSubmit ]);

  useUpdateEffect(() => {

    const nonEmptyQuery = query === ""
      && Object.values(
        filters ?? {},
      ).filter((value) => (Array.isArray(value) ? value.length : !!value)).length === 0;

    if (nonEmptyQuery && !allowEmptySearch) {
      abortController.current.abort();
      setLoading(false);
      setResults([]);
    } else if ((prevQuery.current !== query || prevFilters.current !== filters) && page !== 1) {
      // Query has changed, and page is not 1, so reset the page
      // and let the next effect trigger do the searching
      setPage(1);
    } else {
      // Otherwise, search regularly
      prevQuery.current = query;
      prevFilters.current = filters ?? {};
      setLoading(true);
      void lookup(query, page, filters);
    }

  }, [ query, page, filters ]);

  return {
    query,
    setQuery,
    page,
    setPage,
    loading,
    results,
    onSubmit,
  } as const;

}
